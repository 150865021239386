import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import styles from './Download.module.scss';
import axios from 'axios';
import { Button } from 'react-bootstrap';

export default class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvUrl: null,
      excelUrl: null,
      pdfUrl: null
    }
  }

  getDownloadUrl = () => {
    axios.get(`${this.props.url}/billing/v1/invoices`, {
      params: {
        invoiceNumber: this.props.invoiceNumber,
        accessCode: this.props.accessCode
      }
    }).then((response) => {
      const uriEncodedAccessCode = encodeURIComponent(response.data.links[0].href.split('=')[2]);
      const pdfLink = response.data.links[0].href.split('=')[0].concat(response.data.links[0].href.split('=')[1], '=', uriEncodedAccessCode);
      this.setState({
        excelUrl: `${this.props.url}/billing/v1/invoice/${this.props.id}/specification?accessCode=${encodeURIComponent(this.props.accessCode)}&option=STANDARD_EXCEL`,
        csvUrl: `${this.props.url}/billing/v1/invoice/${this.props.id}/specification?accessCode=${encodeURIComponent(this.props.accessCode)}&option=STANDARD_CSV`,
        pdfUrl: this.props.url + pdfLink,
      })
    }).catch(() => {
      this.props.urlError();
    })
  };

  componentDidMount() {
    this.getDownloadUrl();
  }

  componentWillUnmount() {
    this.props.downloadUnmount();
  }

  handleOnClick = () => {
    this.props.history.push('/');
  };

  render() {
    return <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles.header}>Fakturadetaljer for</h3>
          <h4>fakturanr. <span className={styles.headerInvoiceNumber}>{this.props.invoiceNumber}</span></h4>
          <br/>
          <div>Last ned filtype</div>
          <ul className={styles.download}>
            <li className={styles.downloadItem}>
              Faktura - PDF
              <a href={this.state.pdfUrl} target={'_blank'} rel={'noopener noreferrer'}>
                <span className={styles.downloadItemIcon}>
                    <FontAwesomeIcon icon={faDownload}/>
                </span>
              </a>
            </li>
            <li>
              Fakturaspesifikasjon - CSV
              <a href={this.state.csvUrl} target={'_blank'} rel={'noopener noreferrer'}>
                <span className={styles.downloadItemIcon}>
                  <FontAwesomeIcon icon={faDownload}/>
                </span>
              </a>
            </li>
            <li>
              Fakturaspesifikasjon - Excel
              <a href={this.state.excelUrl} target={'_blank'} rel={'noopener noreferrer'}>
                <span className={styles.downloadItemIcon}>
                  <FontAwesomeIcon icon={faDownload}/>
                </span>
              </a>
            </li>
          </ul>
          <Button onClick={this.handleOnClick} className={styles.backBtn} alt={'Back button'}>Tilbake</Button>
        </div>
      </div>
    </>;
  }
}
