import React, { Component } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import styles from './App.module.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Download from './components/download/Download';
import Login from './components/login/Login';
import axios from 'axios';
import { dataServices } from 'ambita-components-core';

const { config } = dataServices;

let url;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: '',
      format: '',
      id: null,
      invoiceNumber: '',
      isLoggedIn: false,
      loginFailed: false
    }
  }

  loginUrlHandler = () => {
      const url = window.location.pathname;
      const string = url.split('&');
      const invoiceNumberInUrl = string[0].split('=')[1];
      const accessCodeInUrl = string[1].split('=')[1];

      if (invoiceNumberInUrl && accessCodeInUrl) {
        this.loginHandler(invoiceNumberInUrl, accessCodeInUrl);
      }
  };

  loginHandler = (invoiceNumber, accessCode) => {
    invoiceNumber && accessCode ? this.setState({
      invoiceNumber: invoiceNumber,
      accessCode: accessCode,
      loginFailed: false
    }, () => {
      this.fetchInvoice();
    }) : this.setState({ loginFailed: true, isLoggedIn: false }, () => {
      this.fetchInvoice();
    });
  };

  urlError = () => {
    this.setState({
      isLoggedIn: false,
      loginFailed: true
    })
  };

  downloadUnmount = () => {
    this.setState({ isLoggedIn: false, invoiceNumber: '' });
  };

  fetchInvoice = () => {
    axios.get(`${url}/billing/v1/invoices`, {
      params: {
        invoiceNumber: this.state.invoiceNumber,
        accessCode: this.state.accessCode
      }
    }).then((response) => {
      this.setState({
        id: response.data.id,
        isLoggedIn: true,
        loginFailed: false
      })
    }).catch((error) => {
      console.log('error: ', error);
      this.setState({
        isLoggedIn: false,
        loginFailed: true
      });
    });
  };

  componentDidMount() {
    url = config.isProduction() ? config.PROD_API_HOST : config.BETA_API_HOST;
    const invoiceNumber = 'invoiceNumber';
    const accessCode = 'accessCode';
    if (window.location.pathname.includes(invoiceNumber) && window.location.pathname.includes(accessCode)) {
      this.loginUrlHandler();
    }
  }

  render() {
    return (
      <Router>
        <div className={styles.container}>
          <Header/>
          <div className={styles.content}>
            <Switch>
              {!this.state.isLoggedIn && (
                <Route path="/" render={(props) => (
                  <Login {...props}
                         invoiceNumber={this.state.invoiceNumber}
                         accessCode={this.state.accessCode}
                         loginHandler={this.loginHandler}
                         loginFailed={this.state.loginFailed}/>)}>
                </Route>)}
              {this.state.isLoggedIn && (
                <Route exact strict path={`/invoiceNumber=:invoiceNumber&accessCode=:accessCode`} render={(props) => (
                  <Download {...props}
                            url={url}
                            urlError={this.urlError}
                            id={this.state.id}
                            invoiceNumber={this.state.invoiceNumber}
                            accessCode={this.state.accessCode}
                            isLoggedIn={this.state.isLoggedIn}
                            downloadUnmount={this.downloadUnmount}/>
                )}/>)}
            </Switch>
          </div>
          <Footer/>
        </div>
      </Router>
    );
  }
};
